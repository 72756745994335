body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  overflow: hidden;
  overflow-x: hidden !important;
  /* -ms-overflow-style: none !important;
  scrollbar-width: none !important; */
  background-color: #fff;
}

.no-dnd * {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.hiddenScrollBar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hiddenScrollBar::-webkit-scrollbar {
  display: none;
}

.hiddenScrollBarAndChildren {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.hiddenScrollBarAndChildren *::-webkit-scrollbar {
  display: none;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

html {
  height: -webkit-fill-available;
}

:root[mode="md"] {
  --ion-font-family: inherit;
  font-family: inherit;
}

fieldset {
  border: none;
  background-color: none;
  background: none;
  outline: none;
}

iframe {
  border: none;
  background-color: none;
  background: none;
  outline: none;
}

input,
textarea {
  border: none;
  background-color: none;
  background: none;
  outline: none;
  -webkit-user-select: text !important; /* Safari */
  -moz-user-select: text !important; /* Firefox */
  -ms-user-select: text !important; /* IE10+/Edge */
  user-select: text !important; /* Standard */
  -webkit-user-select: text !important;
}

input::placeholder {
  color: grey;
}

button {
  border: none;
  background-color: none;
  background: none;
  outline: none;
  padding: 0;
  margin: 0;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  padding: 0;
  margin: 0;
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

a:visited {
  color: inherit;
}

.product-link {
  border-radius: 25px;
  color: #242424;
  padding: 8px 10px;
  width: 93%;
}

.product-link:hover {
  background-color: #e4e4e475;
}

.hover:hover {
  background-color: #e4e4e475;
}

.hover:active {
  background-color: #21018a;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-weight: 200;
  margin: 0;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

span.ripple {
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 600ms linear;
  background-color: rgba(255, 255, 255, 0.2);
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}

@keyframes bubble {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

.normalBounced:active {
  transform: scale(0.9);
}

.littleBounced:active {
  transform: scale(0.98);
}

.nav-link-menu {
  display: flex;
  align-items: center;
  margin-top: 0;
  height: 50px;
  text-decoration: none;
  font-weight: 500;
  transition: all 1s ease-out;
}
.nav-link-menu:hover {
  background: #b6bbed;
  color: white;
}

@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    height: -webkit-fill-available;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  body {
    height: 100vh;
    overflow: auto;
    height: -webkit-fill-available;
  }
  html {
    height: -webkit-fill-available;
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(50, 50, 50, 0.4);
    -webkit-transform: scale(1);
  }
  10% {
    -webkit-transform: scale(0.95);
  }
  40% {
    -webkit-transform: scale(1);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(50, 50, 50, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(50, 50, 50, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(50, 50, 50, 0.4);
    box-shadow: 0 0 0 0 rgba(50, 50, 50, 0.4);
    transform: scale(1);
  }
  20% {
    transform: scale(0.95);
  }
  60% {
    transform: scale(1);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(50, 50, 50, 0);
    box-shadow: 0 0 0 10px rgba(50, 50, 50, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(50, 50, 50, 0);
    box-shadow: 0 0 0 0 rgba(50, 50, 50, 0);
  }
}

.date-picker input {
  color: hsl(0, 0%, 20%);
  background-color: #f2f3f5;
  border-radius: 10px;
  border: none;
  width: 120%;
}

.date-picker input:focus {
  color: hsl(0, 0%, 20%);
  background-color: #f2f3f5;
}

.loader {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto;
}

/* LOADER 1 */

#loader-1:before,
#loader-1:after {
  content: "";
  position: absolute;
  top: -5px;
  left: -10px;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 4px solid transparent;
  border-top-color: #35ccc4;
}

#loader-1:before {
  z-index: 100;
  animation: spin 1s infinite;
}

#loader-1:after {
  border: 4px solid rgb(233, 233, 233);
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
